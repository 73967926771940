<template>
  <div class="index">
    <div class="chat-list">
      <div class="nav-title">上传表情</div>
      <div class="chat-list-user">
        <div v-for="(item,index) in chatList"
             :key="index"
             @click="lookChatRecords(index)"
             :class="current == index?'active-background':'user-info'">
          <div class="expression-steps">
            <p>{{item.name}}</p>
            <p v-if="item.num">{{item.num}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 上传新表情 -->
    <upload-new-expression v-if="current == 0" ref='expression' @editFinsh="editFinsh"></upload-new-expression>
    <!-- 上传记录 -->
    <upload-record v-if="current == 1" @expressionDetail="expressionDetail" @delSuccess="delSuccess"></upload-record>
  </div>
</template>

<script>
import uploadNewExpression from "./uploadNewExpression"
import uploadRecord from "./uploadRecord"

export default {
  name: 'uploadExpression',
  components: {
    uploadNewExpression,
    uploadRecord
  },
  data () {
    return {
      current: 0,
      isShow: true,
      chatList: [
        { name: '上传新表情' },
        { name: '上传记录', num: 28 },
      ],
    }
  },
  created () {
     this.getList()
  },
  methods: {
    // 查看对应用户聊天记录
    lookChatRecords (index) {
      this.current = index
      this.isShow = false
    },
    //获取列表 设置上传记录条数
   getList(){
     this.$api.upload.getUploadExpression().then(res => {
       this.chatList[1].num = res.result.total
      })
   },
   //上传列表组件传来的值
   expressionDetail(value){
     this.current = 0
     setTimeout(()=>{
       this.$refs.expression.editData(value)
     },500)
   },
   //更新vuex里面得值
    updateNum(){
     this.$store.dispatch('changeNum', "上传表情").then(res=>{
      //  console.log("vuex上传表情数值",res)
     }) //vuex更新数量 传path 
    },
    //上传记录删除成功 触发 更新数量
    delSuccess(){
      this.getList()
       this.updateNum()
    },
   //上传表情组件传来的值
   editFinsh(value){
     if(value=='edit'){
       this.current = 1
       this.getList()
       this.updateNum()
     }
     if(value=='save'){
       this.getList()
       this.updateNum()
     }
   }
  }
};
</script>

<style scoped>
.index {
  display: flex;
  flex: 1;
}
.chat-list {
  width: 253px;
  user-select: none;
  cursor: pointer;
  height: 100%;
  background: #ffffff;
}
.chat-list .chat-list-user {
  height: 100%;
  /* height: 473px; */
  overflow-x: hidden;
  overflow-y: auto;
}
.nav-title {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  letter-spacing: 2px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: #f5f7fa;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-list-user .user-info {
  height: 57px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.user-info:hover {
  background: #f5f7fa;
}
.expression-steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #313233;
}
.expression-steps p:nth-child(1) {
  margin-right: 8px;
}
.expression-steps p:nth-child(2) {
  font-size: 14px;
  color: #7b7d80;
}
.active-background {
  height: 57px;
  display: flex;
  align-items: center;
  background: #f5f7fa;
  padding: 0 12px;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
}
</style>