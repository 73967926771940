<template>
  <div class="chat-window">
    <div class="chat-title">上传新表情</div>
    <div class="chat-container">
      <el-steps :active="active" :space="100">
        <el-step title="填写信息"></el-step>
        <el-step title="上传表情"></el-step>
      </el-steps>
      <el-form
        v-if="active == 1"
        :model="model"
        label-position="top"
        size="small"
        class="content"
      >
        <el-form-item label="表情名称">
          <el-input
            v-model="model.expressionName"
            class="input-style"
            maxlength="8"
            placeholder="请填写 8 个汉字以内的表情名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="表情介绍">
          <el-input
            v-model="model.expressionIntroduce"
            class="input-style"
            maxlength="80"
            show-word-limit
            placeholder="请填写 80 个汉字以内的表情介绍"
          ></el-input>
        </el-form-item>
        <el-form-item label="表情主图类型">
          <el-checkbox :true-label="0" :false-label="1" v-model="model.type"
            >动态图</el-checkbox
          >
          <el-checkbox :true-label="1" :false-label="0" v-model="model.type"
            >静态图</el-checkbox
          >
        </el-form-item>
        <el-form-item label="表情海报">
          <div class="img_require">
            <!-- <p>png 格式，750 ×400 像素，每张不超过5M</p> -->
            <p>750 ×400 像素，每张不超过5M</p>
          </div>
          <el-upload
            class="upload-demo"
            drag
            :action="host"
            :show-file-list="false"
            :on-success="handleAvatarSuccess1"
            :before-upload="beforeAvatarUpload1"
            :data="ossForm"
            :multiple="false"
          >
            <img src="@/assets/images/ic_shangchuan@2x.png" />
            <div class="el-upload__text">点击上传或将表情图片拖动到此区域</div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div class="posterBox" v-if="model.expressionPoster != ''">
            <div class="posterBoxTop">
              <div class="posterBoxTopText">表情海报</div>
              <img
                src="@/assets/images/ic_tuichu@2x.png"
                @click="model.expressionPoster = ''"
                class="posterBoxTopImg"
                alt=""
              />
            </div>
            <div class="posterContainerBox">
              <img
                :src="model.expressionPoster"
                alt=""
                class="posterContainerBoxImg"
              />
            </div>
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-if="active == 2"
        :model="model"
        label-position="top"
        size="small"
        class="content-two"
      >
        <el-form-item label="表情图片主图">
          <div class="img_require">
            <p>GIF 格式，240 ×240 像素，每张不超过500 KB</p>
            <p>数量为8</p>
            <p>
              只能选择动态或静态其中一种，动态图应设置循环播放，节奏流畅不卡顿
            </p>
            <p>合理安排图片布局，每张图片不应有过多留白</p>
            <p>同一套表情中各表情风格须统一</p>
            <p>表情主图命名为：01, 02, 03 ......</p>
          </div>
          <el-upload
            class="upload-demo"
            drag
            :action="host"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :data="ossForm"
            multiple
          >
            <img src="@/assets/images/ic_shangchuan@2x.png" />
            <div class="el-upload__text">点击上传或将表情图片拖动到此区域</div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div class="imgList">
            <div
              class="imgCardItem"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <!-- 卡片上方的数字和关闭按钮 -->
              <div class="imgCardItemTop">
                <div class="imgCardItemTopNum">{{ item.detailName }}</div>
                <img
                  src="@/assets/images/ic_tuichu@2x.png"
                  class="imgCardItemTopImg"
                  alt=""
                  @click="deleteImgCard(index)"
                />
              </div>
              <!-- 卡片中间的图片 -->
              <div class="imgContainerBox">
                <img :src="item.imageUrl" alt="" class="imgContainer" />
              </div>
              <!-- <div class="imgCardItemUnder">
                  <input type="text" class="imgCardItemUnderInput" placeholder="请输入表情含义词" v-model="item.meaning">
              </div> -->
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="next">
      <el-button
        v-if="active == 1"
        type="primary"
        round
        size="small"
        @click="handleNext"
        style="border: none;"
        :style="{background:nextStatus ? '#4db4d4':'rgba(77, 180, 212, 0.5)'}"
        >下一步</el-button
      >
      <el-button
        v-if="active == 2"
        type="primary"
        round
        size="small"
        style="background: #4db4d4"
        @click="handleBack"
        >上一步</el-button
      >
      <el-button
        v-if="active == 2"
        type="primary"
        round
        size="small"
        style="background: #4db4d4"
        @click="confirmHanderler"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  // 上传新表情
  name: "uploadNewExpression",
  data() {
    return {
      active: 1,
      model: {
        detailList: [],
        type: null,
        expressionIntroduce: "", //表情介绍
        expressionName: "", //表情名称
        expressionPoster: "", //表情海报
      },
      imageUrl: "",
      ossForm: {}, //oss配置
      host: "", //上传地址
      imgArr: [], //表情包数组
      detailList: [],
      upLoadStatus: true,
      checkedType: [],
      fromStatus: false, //是否来自上传列表的编辑
      expressionId: "", //更新修改的表情包id
    };
  },
  created() {},
  watch: {
    //监听图片列表的变化 动态更新图片命名和索引
    detailList(newValue) {
      newValue.forEach((item, index) => {
        this.$set(item, "detailName", "0" + (index + 1));
        this.$set(item, "sort", index + 1);
      });
    },
  },
  computed: {
    //下一步状态
    nextStatus() {
      if (
        this.model.type !== null &&
        this.model.expressionIntroduce != "" &&
        this.model.expressionName != "" &&
        this.model.expressionPoster != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    //下一步
    handleNext() {
      if (!this.nextStatus) {
        if (!this.model.expressionName) {
          this.$message({
            message: "请填写表情包名称",
            type: "warning",
          });
          return;
        }
        if (!this.model.expressionIntroduce) {
          this.$message({
            message: "请填写表情包介绍",
            type: "warning",
          });
          return;
        }
        if (this.model.type === null) {
          this.$message({
            message: "请选择动态图或者静态图",
            type: "warning",
          });
          return;
        }
        if (!this.model.expressionPoster) {
          this.$message({
            message: "请上传表情包海报",
            type: "warning",
          });
        }
        return;
      }
      this.active++;
      this.$store.dispatch("changeMemeNum");
    },
    //确定按钮的处理  上传表情包
    confirmHanderler() {
      //判断 是否这些必填值填写
      if (this.model.expressionName == "") {
        this.$message({
          message: "请填写表情包名称",
          type: "warning",
        });
        return;
      }
      if (this.model.expressionName.length > 8) {
        this.$message({
          message: "表情包名称需在8个汉字以内",
          type: "warning",
        });
        return;
      }
      if (this.model.expressionIntroduce == "") {
        this.$message({
          message: "请填写表情包介绍",
          type: "warning",
        });
        return;
      }
      if (this.model.type == null) {
        this.$message({
          message: "请选择是动态图，静态图",
          type: "warning",
        });
        return;
      }
      if (this.model.expressionPoster == "") {
        this.$message({
          message: "请上传表情海报",
          type: "warning",
        });
        return;
      }

      if (this.detailList.length == 0) {
        this.$message({
          message: "请上传表情",
          type: "warning",
        });
        return;
      }
      if (this.detailList.length != 8) {
        this.$message({
          message: "表情数量为8个",
          type: "warning",
        });
        return;
      }
      // let isMean = true
      // this.detailList.forEach(item=>{
      //    if(!item.meaning){
      //      isMean = false
      //    }
      // })
      // if(!isMean){
      //   this.$message({
      //     message: '表情含义不完整',
      //     type: 'warning'
      //   });
      //   return
      // }
      this.model.detailList = this.detailList;
      //编辑

      if (this.fromStatus) {
        console.log("编辑==", this.fromStatus);
        api.other
          .expressionUpdate(this.expressionId, this.model)
          .then((res) => {
            console.log(res);
            this.$message({
              showClose: true,
              message: "修该表情包成功",
              type: "success",
            });
            this.$emit("editFinsh", "edit");
            this.clearForm();
          });
      } else {
        //  console.log("保存==",this.fromStatus)
        api.other
          .expressionUpload(this.model)
          .then((res) => {
            if (res.code != 200) {
              this.$message({
                message: res.message,
                type: "warning",
              });
            } else {
              this.$message({
                showClose: true,
                message: "上传成功",
                type: "success",
              });
            }

            this.$emit("editFinsh", "save");
            this.clearForm();
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.message);
          });
      }
    },
    //返回上一步
    handleBack() {
      this.active--;
    },
    //关闭按钮删除这张卡片
    deleteImgCard(index) {
      //假删除只是列表删除因为和请求的detailList不一样
      this.detailList.splice(index, 1);
    },
    //上传图片成功后
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (!this.upLoadStatus) {
        return;
      }
      let obj = {};
      obj.imageUrl = res.result.filename;
      this.detailList.push(obj);
      // this.detailList.forEach((item,index)=>{
      //     this.$set(item,'detailName',"0" + (index+1))
      //     this.$set(item,'sort',index+1)
      // })
    },
    //上传图片前
    beforeAvatarUpload(file) {
      this.upLoadStatus = true;
      if (this.detailList.length > 8) {
        this.$message({
          message: "每次最多上传八张",
          type: "warning",
        });
        this.upLoadStatus = false;
        return;
      }
      //限制是gif和500kb
      const isGif = file.type === "image/gif";
      const isSize = (file.size / 1024 / 1024 / 1024) * 1000000 < 500;
      if (!isGif) {
        this.$message.error("上传表情图片只能是 gif 格式!");
        this.upLoadStatus = false;
        return;
      }
      if (!isSize) {
        this.$message.error("上传表情图片大小不能超过 500kb!");
        this.upLoadStatus = false;
        return;
      }
      //promise 构造oss参数
      return new Promise((resolve, reject) => {
        api.upload
          .policy()
          .then((res) => {
            this.host = res.result.host;
            this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
            this.ossForm.callback = res.result.callback;
            this.ossForm.key = res.result.dir + "/" + file.uid + file.name;
            this.ossForm.policy = res.result.policy;
            this.ossForm.signature = res.result.signature;
            this.ossForm.success_action_status = 200;
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      });
    },
    //上传图片成功后  海报
    handleAvatarSuccess1(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.model.expressionPoster = res.result.filename;
    },
    //上传图片前 海报
    beforeAvatarUpload1(file) {
      this.upLoadStatus = true;
      //限制是gif和500kb
      // const isPng = file.type === "image/png";
      const isSize = file.size / 1024 / 1024 < 5;
      // if (!isPng) {
      //   this.$message.error("上传表情海报图片只能是 png 格式!");
      //   this.upLoadStatus=false
      //   return;
      // }
      if (!isSize) {
        this.$message.error("上传表情海报图片大小不能超过 5M!");
        this.upLoadStatus = false;
        return;
      }
      //promise 构造oss参数
      return new Promise((resolve, reject) => {
        api.upload
          .policy()
          .then((res) => {
            this.host = res.result.host;
            this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
            this.ossForm.callback = res.result.callback;
            this.ossForm.key = res.result.dir + "/" + file.uid + file.name;
            this.ossForm.policy = res.result.policy;
            this.ossForm.signature = res.result.signature;
            this.ossForm.success_action_status = 200;
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      });
    },
    //编辑 重新赋值
    editData(value) {
      // this.$message({
      //     showClose: true,
      //     message: '如果修改表情名称，则是添加新的表情包',
      //     type: 'warning'
      //   });
      this.fromStatus = true; //设置来自上传列表的编辑
      this.model.expressionName = value.expressionName;
      this.model.expressionIntroduce = value.expressionIntroduce;
      this.model.expressionPoster = value.expressionPoster;
      this.model.type = value.type;
      this.expressionId = value.id.toString();
      value.detailList.forEach((item) => {
        this.$set(item, "id", item.id.toString());
      });
      this.detailList = value.detailList;
    },
    //清空表单
    clearForm() {
      this.fromStatus = false;
      this.model.expressionName = "";
      this.model.expressionIntroduce = "";
      this.model.expressionPoster = "";
      this.model.type = null;
      this.detailList = [];
      this.model.detailList = [];
    },
  },
};
</script>

<style scoped>
.chat-window {
  min-width: 506px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
}
.chat-window .chat-title {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  color: #313233;
  letter-spacing: 2px;
  padding: 0 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-window .chat-container {
  min-height: 472px;
  height: 100%;
  padding: 20px 0 20px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  border-bottom: 1px solid #dcdfe6;
  overflow-x: hidden;
  overflow-y: auto;
}
.chat-container .el-steps--horizontal {
  margin-bottom: 20px;
}

.el-form--label-top >>> .el-form-item__label {
  padding: 0;
}
.next {
  height: 68px;
  text-align: right;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
.next button {
  width: 70px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.input-style /deep/ input {
  border: none !important;
  padding-left: 0;
}
.content-two .img_require p {
  font-size: 12px;
  color: #abaeb3;
  line-height: 20px;
}
.content-two {
  padding-right: 20px;
}
.content-two .upload-demo {
  margin: 20px 0;
}
.content .upload-demo /deep/ .el-upload {
  width: 100%;
}
.content .upload-demo {
  margin: 20px 0;
}
.content .img_require p {
  font-size: 12px;
  color: #abaeb3;
  line-height: 20px;
}

.content-two .upload-demo /deep/ .el-upload {
  width: 100%;
}
.content-two .upload-demo /deep/ .el-upload-dragger {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
}
.content-two .upload-demo img {
  width: 32px;
  height: 32px;
}
.content-two .upload-demo .el-upload__text {
  font-size: 8px;
  color: #7a7c80;
}

.content .upload-demo /deep/ .el-upload-dragger {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
}
.content .upload-demo img {
  width: 32px;
  height: 32px;
}
.content .upload-demo .el-upload__text {
  font-size: 8px;
  color: #7a7c80;
}
.imgList {
  display: flex;
  flex-wrap: wrap;
}
.imgCardItem {
  width: 120px;
  height: 166px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.imgCardItemTop {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe6;
}
.imgCardItemTopNum {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  margin-left: 10px;
  color: #313233;
}
.imgCardItemTopImg {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0;
}
.imgContainerBox {
  width: 120px;
  height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #dcdfe6; */
}
.imgContainer {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}
.imgCardItemUnder {
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgCardItemUnderInput {
  width: 100px;
  border: none;
  font-size: 12px;
  color: #313233;
  background: none;
  outline: none;
  text-align: center;
}
.imgCardItemUnderInput:focus {
  border: none;
  text-align: center;
}
.imgCardItemUnderInput:blur {
  border: none;
  text-align: center;
}
.posterBox {
  width: 240px;
  height: 340px;
  border: 1px solid #dcdfe6;
}
.posterBoxTop {
  width: 240px;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe6;
}
.posterBoxTopText {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}
.posterBoxTopImg {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}
.posterContainerBox {
  width: 240px;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.posterContainerBoxImg {
  width: 220px;
  height: 220px;
  flex-shrink: 0;
}
.activeBlueBg {
  background: #4db4d4;
}
</style>