<template>
  <div class="chat-window">
    <div class="chat-title">
      <p>上传记录</p>
      <!-- <p>21</p> -->
      <p>{{ total }}</p>
    </div>
    <div class="list-content">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          label="作品名称"
          align="center"
          width="250px"
          prop="name"
        >
          <template slot-scope="scope">{{ scope.row.name }}</template>
          <template slot-scope="scope">{{ scope.row.expressionName }}</template>
        </el-table-column>
        <el-table-column label="时间" align="center" prop="time">
          <template slot-scope="scope">{{ scope.row.time }}</template>
          <template slot-scope="scope">{{ scope.row.createTime }}</template>
        </el-table-column>
        <el-table-column label="状态" width="120px" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >修改</el-button
            >
            <span class="blueText"> / </span>
            <el-popconfirm title="您确定要删除这个表情吗？" @confirm="handleDel(scope.row)">
              <el-button 
                 slot="reference"
                 type="text"
                 class="redText"
                 >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import upload from "@/api/apiModel/upload";
import api from "@/api/api";
export default {
  // 上传的表情记录
  name: "uploadRecord",
  data() {
    return {
      tableData: [
        { id: 1, name: "祝福表情", time: "2021-07-30 12:12" },
        { id: 2, name: "熊猫头表情", time: "2021-07-30 14:12" },
        { id: 3, name: "暴漫表情", time: "2021-07-30 16:12" },
        { id: 4, name: "祝福表情", time: "2021-07-30 12:12" },
        { id: 5, name: "熊猫头表情", time: "2021-07-30 14:12" },
        { id: 6, name: "暴漫表情", time: "2021-07-30 16:12" },
        { id: 7, name: "祝福表情", time: "2021-07-30 12:12" },
        { id: 8, name: "熊猫头表情", time: "2021-07-30 14:12" },
        { id: 9, name: "暴漫表情", time: "2021-07-30 16:12" },
        { id: 10, name: "祝福表情", time: "2021-07-30 12:12" },
        { id: 11, name: "熊猫头表情", time: "2021-07-30 14:12" },
        { id: 12, name: "暴漫表情", time: "2021-07-30 16:12" },
        { id: 13, name: "祝福表情", time: "2021-07-30 12:12" },
        { id: 14, name: "熊猫头表情", time: "2021-07-30 14:12" },
        { id: 15, name: "暴漫表情", time: "2021-07-30 16:12" },
      ],
      tableData: [],
      total: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 编辑 表情
    handleEdit(row) {
      upload.getExpressionDetail(row.id).then((res) => {
        let data = res.result;
        this.$emit("expressionDetail", data);
      });
    },
    //删除表情
    handleDel(row) {
      console.log(row)
      api.other.deleteExpression(row.id).then(res=>{
         if(res.code!=200){
           this.$message.error(res.message);
         }else{
           this.getList()
           this.$emit("delSuccess")
           this.$message({
           message: '删除成功',
          type: 'success'
        });
         }
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    // 获取上传表情记录列表
    getList() {
      upload.getUploadExpression().then((res) => {
        this.tableData = res.result.records;
        this.total = res.result.total;
      });
    },
  },
};
</script>

<style scoped>
.chat-window {
  min-width: 506px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
}
.chat-window .chat-title {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  color: #313233;
  letter-spacing: 2px;
  padding: 0 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-window .chat-title p:nth-child(2) {
  font-size: 18px;
  color: #7b7d80;
  margin-left: 8px;
}
.list-content {
  height: 540px;
  overflow-x: hidden;
  overflow-y: auto;
}
.blueText {
  color: #409eff;
}
.redText {
  color: #f56c6c;
}
</style>